export enum Status {
  QUEUED = 'queued',
  CURRENTLY_EDITING = 'currently_editing',
  DELIVERED = 'delivered',
  NEEDS_REEDIT = 'needs_reedits',
  DRAFT = 'draft',
  COMPLETE = 'complete',
  PREPARING_FINAL_FILES = 'preparing_final_files',
  APPROVED = 'approved',
  FINAL_FILES_DELIVERED = 'final_files_delivered',
}

export enum EditableStatus {
  QUEUED = 'queued',
  DRAFT = 'draft',
}

export enum Stage {
  QUEUED = 'queued',
  DELIVERED = 'delivered',
  DRAFT = 'draft',
  COMPLETED = 'completed',
}

export enum Types {
  Unknown = '',
  Ad = 'ad',
  CourseVideo = 'course_video',
  MotionGraphics = 'motion_graphics',
  Podcast = 'podcast',
  Reel = 'reel',
  Story = 'story',
  TalkingHead = 'talking_head',
  Tiktok = 'tiktok',
  Tutorial = 'tutorial',
  Vlog = 'vlog',
  YtVideo = 'yt_video',
  YtShorts = 'yt_shorts',
  Other = 'other',
}
