import React, { useEffect } from 'react';
import { cn } from 'src/lib/utils';
import { appRoutes } from 'src/routes';
import { generatePath, Link, useLocation, useNavigate } from 'react-router-dom';
import { useRequestId } from 'src/features/requests/use-request-id';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { DecoratedRequestProvider } from 'src/features/requests/use-decorated-request-context';
import { ErrorBoundary } from 'react-error-boundary';
import { RequestQueryProvider } from 'src/features/requests/use-request-query-context';
import { useRequestsCounts } from 'src/models';
import {
  RequestDefaultPageMainContent,
  RequestDefaultPageSkeleton,
  RequestPageContainer,
  RequestPageContentMain,
} from 'src/pages/request/request-page/request-default-page';
import {
  RequestMultiOutcomePageMainContent,
  RequestMultiOutcomePageSideContent,
  RequestMultiOutcomeSkeleton,
} from 'src/pages/request/request-page/request-multi-outcome-page';
import { SpaceErrorFallback } from 'src/features/fallback';
import { RequestResponse } from 'src/lib/services/api/request-api';
import { RequestDefaultPageContentTop } from 'src/pages/request/request-page/request-default-page-content-top';
import { RequestMultiOutcomePageContentTop } from 'src/pages/request/request-page/request-multi-outcome-page-content-top';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from 'src/components/ui/breadcrumb';
import { Button } from 'src/components/ui/button';
import { ChevronLeft, Video } from 'lucide-react';
import { PageHeading, PageHeadingTitle } from 'src/components/ui/page-heading';
import { useTranslation } from 'react-i18next';

const RequestPageFallbackContent: React.FC<{ error: any; resetErrorBoundary: () => any }> = ({
  error,
  resetErrorBoundary,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={'tw-flex tw-flex-col tw-gap-4 md:tw-gap-10'}>
      <PageHeading>
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <Button
                variant={'unset'}
                size={'iconXs'}
                onClick={() => {
                  if (location.key !== 'default') {
                    history.back();
                  } else {
                    navigate(appRoutes.requestsList);
                  }
                }}
              >
                <ChevronLeft className={'tw-size-4'} />
              </Button>
              <BreadcrumbLink asChild>
                <Link to={appRoutes.dashboard}>
                  {t('common:dashboard', {
                    defaultValue: 'Dashboard',
                  })}
                </Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to={appRoutes.requestsList}>
                  {t('common:requests', {
                    defaultValue: 'Requests',
                  })}
                </Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbPage>
              {t('common:request', {
                defaultValue: 'Request',
              })}
            </BreadcrumbPage>
          </BreadcrumbList>
        </Breadcrumb>
        <PageHeadingTitle className={'tw-flex-nowrap'}>
          <div>
            <Video className={'tw-size-6'} />
          </div>
          <span className={'tw-line-clamp-1'}>
            {t('common:request', { defaultValue: 'Request' })}
          </span>
        </PageHeadingTitle>
      </PageHeading>

      <SpaceErrorFallback error={error} resetErrorBoundary={resetErrorBoundary} />
    </div>
  );
};

const RequestPageSkeletonResolver: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    flow?: RequestResponse['flow'];
  }
> = ({ flow = 'default', ...props }) => {
  switch (flow) {
    case 'multi_outcome':
      return <RequestMultiOutcomeSkeleton {...props} />;
    case 'default':
    case 'outcome':
    default:
      return <RequestDefaultPageSkeleton {...props} />;
  }
};

const RequestFlowContentTopResolver: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    flow?: RequestResponse['flow'];
  }
> = ({ flow = 'default', ...props }) => {
  switch (flow) {
    case 'multi_outcome':
      return <RequestMultiOutcomePageContentTop {...props} />;
    default:
      return <RequestDefaultPageContentTop {...props} />;
  }
};

const RequestFlowMainContentResolver: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    flow?: RequestResponse['flow'];
  }
> = ({ flow = 'default', className, ...props }) => {
  switch (flow) {
    case 'multi_outcome':
      return (
        <>
          <RequestMultiOutcomePageMainContent className={'tw-col-span-12 laptop:tw-col-span-8'} />
          <RequestMultiOutcomePageSideContent
            className={
              'tw-hidden tw-items-center tw-rounded-lg laptop:tw-col-span-4 laptop:tw-flex laptop:tw-pt-15'
            }
          />
        </>
      );
    default:
      return (
        <RequestDefaultPageMainContent className={cn('tw-col-span-12', className)} {...props} />
      );
  }
};
RequestFlowMainContentResolver.displayName = 'RequestFlowContentResolver';

const RequestPage: React.FC = () => {
  const requestId = useRequestId();
  const navigate = useNavigate();

  const { review, query: requestsCountsQuery } = useRequestsCounts();

  useEffect(() => {
    if (requestsCountsQuery.isSuccess) {
      review(requestId!);
    }
  }, [requestsCountsQuery.isSuccess]);

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }: any) => (
            <RequestPageFallbackContent error={error} resetErrorBoundary={resetErrorBoundary} />
          )}
          onReset={reset}
        >
          <React.Suspense fallback={<RequestPageSkeletonResolver flow={'default'} />}>
            <RequestQueryProvider id={requestId}>
              {({ data }) => {
                // request outcome does not have a page, redirect to parent request
                if (data && data.flow === 'outcome') {
                  navigate(generatePath(appRoutes.request, { requestId: data.parent_id! }), {
                    replace: true,
                  });
                  return null;
                }

                return (
                  <DecoratedRequestProvider request={data}>
                    <RequestPageContainer>
                      <RequestFlowContentTopResolver flow={data?.flow} />
                      <RequestPageContentMain className={'tw-grid-cols-1 md:tw-grid-cols-12'}>
                        <RequestFlowMainContentResolver flow={data?.flow} />
                      </RequestPageContentMain>
                    </RequestPageContainer>
                  </DecoratedRequestProvider>
                );
              }}
            </RequestQueryProvider>
          </React.Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};

export { RequestPageContainer, RequestPage };
