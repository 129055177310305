import { Api } from 'src/api';
import { httpConfig } from 'src/lib/modules/http';
import { AxiosRequestConfig } from 'axios';
import { url } from 'src/lib/utils';
import {
  ChangeStatusRequest,
  RequestOutcomeResponse,
  RequestResponse,
  RequestsTableResponse,
  UpdatePriorityRequest,
} from 'src/lib/services/api/request-api/request-client.types';
import { RevisionResponse } from 'src/lib/services/api/revision-api/revision-client.types';
import { PaginatedResponse } from 'src/api/Client';
import {
  RequestFormObject,
  RequestsCountsResponse,
  SortRequest,
} from 'src/lib/services/api/request-api/request.types';
import { ApiQueryObject } from 'src/lib/services/api-query-params';

const baseUrl = `${httpConfig.options.baseURL}/requests`;

const index = (config?: AxiosRequestConfig): Promise<PaginatedResponse<RequestResponse>> =>
  Api.http.get(baseUrl, config);

const get = (model: string, config?: AxiosRequestConfig): Promise<RequestResponse> =>
  Api.http.get(`${baseUrl}/${model}`, config);

const filter = (
  params?: ApiQueryObject,
  config?: AxiosRequestConfig,
): Promise<PaginatedResponse<RequestsTableResponse>> =>
  Api.http.get(baseUrl, { params, ...config });

const outcomes = (
  model: string,
  config?: AxiosRequestConfig,
): Promise<PaginatedResponse<RequestOutcomeResponse>> =>
  Api.http.get(`${baseUrl}/${model}/outcomes`, config);

export type RequestOutcomesPaginatedResponse = PaginatedResponse<RequestOutcomeResponse>;
export type RequestOutcome = RequestOutcomeResponse;

const sort = (model: string, data: SortRequest, config?: AxiosRequestConfig): Promise<void> =>
  Api.http.post(`${baseUrl}/${model}/sort`, data, config);

const complete = (model: string, config?: AxiosRequestConfig): Promise<void> =>
  Api.http.post(`${baseUrl}/${model}/complete`, config);

const approve = (model: string, config?: AxiosRequestConfig): Promise<void> =>
  Api.http.put(`${baseUrl}/${model}/approve`, config);

const requestReedit = (model: string, config?: AxiosRequestConfig): Promise<void> =>
  Api.http.put(`${baseUrl}/${model}/request-reedit`, config);

const archive = (model: string, config?: AxiosRequestConfig): Promise<void> =>
  Api.http.delete(`${baseUrl}/${model}/archive`, config);

const restore = (model: string, config?: AxiosRequestConfig): Promise<void> =>
  Api.http.post(`${baseUrl}/${model}/restore`, config);

const remove = (model: string, config?: AxiosRequestConfig): Promise<void> =>
  Api.http.delete(`${baseUrl}/${model}`, config);

const clone = (model: string, config?: AxiosRequestConfig): Promise<RequestResponse> =>
  Api.http.post(`${baseUrl}/${model}/clone`, config);

const counts = (
  params?: ApiQueryObject,
  config?: AxiosRequestConfig,
): Promise<RequestsCountsResponse> => Api.http.get(`${baseUrl}/counts`, { params, ...config });

const changeStatus = (
  model: string,
  data: ChangeStatusRequest,
  config?: AxiosRequestConfig,
): Promise<RequestResponse> => Api.http.post(`${baseUrl}/${model}/change-status`, data, config);

const updatePriority = (
  model: string,
  data: UpdatePriorityRequest,
  config?: AxiosRequestConfig,
): Promise<void> => Api.http.put(`${baseUrl}/${model}/update-priority`, data, config);

const store = (data: RequestFormObject, config?: AxiosRequestConfig): Promise<RequestResponse> =>
  Api.http.post(baseUrl, data, config);

const update = (
  model: string,
  data: RequestFormObject,
  config?: AxiosRequestConfig,
): Promise<RequestResponse> => Api.http.put(`${baseUrl}/${model}`, data, config);

/**
 * Not implemented
 * @param model
 * @param config
 */
const revisions = (model: string, config?: AxiosRequestConfig): Promise<RevisionResponse[]> =>
  Api.http.get(url.insertArguments(`${baseUrl}/{model}/revisions`, { model }), config);

type RequestRevisionsResponse = Awaited<ReturnType<typeof revisions>>;
type RequestRevisionsResponseItem = RequestRevisionsResponse[number];

export {
  approve,
  archive,
  changeStatus,
  counts,
  complete,
  clone,
  filter,
  get,
  index,
  outcomes,
  requestReedit,
  remove,
  restore,
  sort,
  store,
  update,
  updatePriority,
};
