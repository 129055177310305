const authQueries = {
  profile: 'profile',
  profileSocialAccounts: 'profileSocialAccounts',
  profileNotificationSettings: 'profileNotificationSettings',
  company: 'company',
  brands: 'brands',
  brand: 'brand',
  companyBrands: 'companyBrands',
  companyPersonnel: 'companyPersonnel',
  requests: 'requests',
  request: 'request',
  requestOutcomes: 'requestOutcomes',
  requestCounts: 'requestCounts',
  revision: 'revision',
  comments: 'comments',
  reactSelect: 'reactSelectAuth',
  notifications: 'notifications',
  unreadNotifications: 'unreadNotifications',
  subscriptionPromotions: 'unreadNotifications',
  videoTemplates: 'videoTemplates',
} as const;

const scopedQueries = {
  infinite: 'infiniteQuery',
  table: 'tableQuery',
} as const;

const globalQueries = {
  stripeProducts: 'stripeProducts',
  media: 'media',
  mediaSignedUrl: 'mediaSignedUrl',
  sharedMedia: 'sharedMedia',
} as const;

export { authQueries, scopedQueries, globalQueries };
export * from './queries.utils';
