import { FilterGroups, useFilters } from 'src/lib/services/api-query-params/use-filters';
import { requestFlow, requestPriority, requestPriorityLabels } from 'src/lib/services/api/request-api';
import { useTranslation } from 'react-i18next';
import {
  approvedRequests,
  needsReeditRequests,
  recentlyUpdatedRequests,
  requestedAttentionRequests,
} from 'src/features/requests/filter-queries';

const useRequestFilters = () => {
  const { t } = useTranslation();
  const { toggleArrayFilter, toggleSingleFilter } = useFilters();

  const filterGroups: FilterGroups = {
    outcomes: {
      label: t('pages/shared:requests_list.filters.outcomes', { defaultValue: 'Outcomes' }),
      filters: [
        {
          label: t('pages/shared:requests_list.filters.flow.default', { defaultValue: 'Single' }),
          updater: toggleArrayFilter,
          filter: {
            id: 'flow',
            value: requestFlow.default,
            operator: 'in',
          },
        },
        {
          label: t('pages/shared:requests_list.filters.flow.multi_outcome', {
            defaultValue: 'Multiple',
          }),
          updater: toggleArrayFilter,
          filter: {
            id: 'flow',
            value: requestFlow.multiOutcome,
            operator: 'in',
          },
        },
      ],
    },
    status_details: {
      label: t('pages/shared:requests_list.filters.status_details', {
        defaultValue: 'Status details',
      }),
      filters: [
        {
          label: t('pages/shared:requests_list.filters.status_details.needs_reedit', {
            defaultValue: 'Needs re-edit',
          }),
          updater: toggleSingleFilter,
          filter: needsReeditRequests.filters[0],
        },
        {
          label: t('pages/shared:requests_list.filters.status_details.needs_attention', {
            defaultValue: 'Needs attention',
          }),
          updater: toggleSingleFilter,
          filter: requestedAttentionRequests.filters[0],
        },
        {
          label: t('pages/shared:requests_list.filters.status_details.approved', {
            defaultValue: 'Approved',
          }),
          updater: toggleSingleFilter,
          filter: approvedRequests.filters[0],
        },
        {
          label: t('pages/shared:requests_list.filters.status_details.recently_updated', {
            defaultValue: 'Recently updated',
          }),
          updater: toggleSingleFilter,
          filter: recentlyUpdatedRequests.filters[0],
        },
      ],
    },
    priority: {
      label: t('pages/shared:requests_list.filters.priority', { defaultValue: 'Priority' }),
      filters: Object.values(requestPriority).map((priority) => ({
        label: t(`pages/shared:requests_list.filters.priority.${priority}`, {
          defaultValue: requestPriorityLabels[priority],
        }),
        updater: toggleArrayFilter,
        filter: {
          id: 'priority',
          value: priority,
          operator: 'in',
        },
      })),
    },
  };

  return { filterGroups };
};

export { useRequestFilters };
